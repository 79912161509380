<template>
    <div class="container">
        <div class="chapter">
        <div class="title">{{detail.chapterName}}</div>
        <div class="page">
            <a :href="preUrl">上一章</a>
            <router-link :to="{path: '/detail/'+this.$route.params.bookId+'.html'}">目录</router-link>
            <a :href="nextUrl">下一章</a>
        </div>
        <InFeedAdsense
            v-if="initialed"
            data-ad-layout-key="-cm+8l-1m-2u+bi"
            data-ad-client="ca-pub-2093034756983913"
            data-ad-slot="8430764648"
            >
        </InFeedAdsense>
        <div class="content" v-html="formatterCotent"></div>
        <InFeedAdsense
            v-if="initialed"
            data-ad-layout-key="-cm+8l-1m-2u+bi"
            data-ad-client="ca-pub-2093034756983913"
            data-ad-slot="8430764648"
            >
        </InFeedAdsense>
        <div class="page">
            <a :href="preUrl">上一章</a>
            <router-link :to="{path: '/detail/'+this.$route.params.bookId+'.html'}">目录</router-link>
            <a :href="nextUrl">下一章</a>
        </div>
    </div>
    </div>
</template>
<script>
import {chapterDetail} from '@/apis/api'
import pako from 'pako'  

export default {
    data(){
        return {
            detail: {},
            preUrl: '',
            nextUrl: '',
            initialed: false
        }
    },
    computed:{
        formatterCotent(){
            if(!this.detail.content) return "";
            try{
                let contet =  '<p>'+this.gzip(this.detail.content).replaceAll('\n','</p><p>')+'</p>';
                return contet
            }catch(e){
                console.log(e)
            }
            return "数据异常"
        }
    },
    mounted(){
        chapterDetail(this.$route.params.bookId,this.$route.params.chapterId).then((data) => {
            this.detail = data.data.data;
            
            if(this.detail) {
                document.title = this.detail.bookName;
            }
            this.preUrl = '/read/'+this.$route.params.bookId+'/'+(parseInt(this.$route.params.chapterId)-1)+'.html'
            this.nextUrl = '/read/'+this.$route.params.bookId+'/'+(parseInt(this.$route.params.chapterId)+1)+'.html'
            this.initialed = true;
        })
        .catch(() => {
            this.initialed = true;
        })
        var d = new Date();
        if(this.isMobile() && (d.getHours() < 7 || d.getHours() > 18))
            document.getElementById("app").className = "dark";
        else
            document.getElementById("app").className = "light";
    },
    methods: {
        pre(){
            if(parseInt(this.$route.params.chapterId) == 1) return;
            window.location.href='/read/'+this.$route.params.bookId+'/'+(parseInt(this.$route.params.chapterId)-1)+'.html'
        },
        next(){
            if(parseInt(this.$route.params.chapterId) == this.detail.totalChapters) return;
            window.location.href='/read/'+this.$route.params.bookId+'/'+(parseInt(this.$route.params.chapterId)+1)+'.html'
        },
        isMobile() {
            return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
        },
        gzip(strData){
            var charData = strData.split('').map(function(x) { return x.charCodeAt(0) })
            var binData = new Uint8Array(charData)
            var data = pako.inflate(binData) 
            return this.Utf8ArrayToStr(data); 
        },
        Utf8ArrayToStr(array) {
            var out, i, len, c;
            var char2, char3;

            out = "";
            len = array.length;
            i = 0;
            while(i < len) {
            c = array[i++];
            switch(c >> 4)
            { 
                case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                    // 0xxxxxxx
                    out += String.fromCharCode(c);
                    break;
                case 12: case 13:
                    // 110x xxxx   10xx xxxx
                    char2 = array[i++];
                    out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                    break;
                case 14:
                    // 1110 xxxx  10xx xxxx  10xx xxxx
                    char2 = array[i++];
                    char3 = array[i++];
                    out += String.fromCharCode(((c & 0x0F) << 12) |
                                ((char2 & 0x3F) << 6) |
                                ((char3 & 0x3F) << 0));
                    break;
                }
            }
            return out
        }
    }
}
</script>
<style lang="less" scoped>
    .container{
        background: url('../assets/chapter_bg.png') repeat;
    }
    .light {
        .chapter{
            background: url('../assets/chapter_bg.png') repeat;
            .title{
                background: url('../assets/chapter_bg.png') repeat;
                max-width: 800px;
            }
        }
    }
    .dark {
        .chapter{
            background: rgb(26, 26, 26);
            color: rgba(255, 255, 255, 0.5);
            .title{
                background-color: rgb(26, 26, 26);
                color: rgba(255, 255, 255, 0.4);
            }
            a {
                color:#fffff1;
            }
        }
    }
    .chapter{
        min-height: 100%;
        padding: 10px;
        max-width: 800px;
        margin: 0 auto;
        font-size: 1.1rem;
        .title{
            position: fixed;
            top:0;
            width:100%;
            font-size: .7rem;
            font-weight: bold;
            padding: 10px;
            margin: 0 auto;
        }
    }
    .page {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 1rem 0;
        margin-top: 20px;
    }
</style>